import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import GameLimits from '../../../../../common/live-casino/GameLimits';
import Play from '../../../../../svg-icons/game-thumbs/Play';
import { LIVE_DEALER_STATUS_OPEN } from '../../../../../../variables';
import { generateGameThumbnailUrl } from '../../../../../../utils/games';
import GameProviderLogo from '../../../../../common/GameProviderLogo';

const GameListItemLive = ({ game, url, as, saveCurrentUrl, dataId, provider, isLargeBg }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const { firstGameImageLink } = useSelector((state) => state.global.data);

    return (
        <div className="game-list-item game-list-item-live" data-id={dataId}>
            {game.status !== LIVE_DEALER_STATUS_OPEN && (
                <div className="game-list-item--table-closed" data-testid="table_closed_overlay">
                    <p>{tr['live_dealer.table_closed']}</p>
                </div>
            )}
            <div
                className="game-list-item--background"
                style={{
                    backgroundImage: `url(${generateGameThumbnailUrl(game, isLargeBg, firstGameImageLink)})`
                }}
            />
            <Link href={url} as={as}>
                <a className="game-list-item--link" onClick={saveCurrentUrl} data-testid={`game_${game.id}`}>
                    <Play />
                </a>
            </Link>
            <Fragment>
                <div className="game-list-item--text--live" />
                <span className="game-list-item--text-title">
                    <div className="game-name">{game.name}</div>
                    <GameProviderLogo slug={provider?.slug} />
                </span>
                {game.betLimits && <GameLimits table={game}/>}
            </Fragment>
        </div>
    );
};

export default GameListItemLive;
