const ZoomedThumbnail = ({ game }) => {
    return (
        <div className="game-list-item--background animated" style={{ backgroundImage: `url(${game.imageSrc})` }}>
            <picture>
                <source srcSet={game.imagePersoWebp} type="image/webp" />
                <img src={game.imagePerso} alt={game.name} />
            </picture>
        </div>
    );
};

export default ZoomedThumbnail;
