import { useState } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { gameDefaultThumbnail } from '../../../../../../utils/gameDefaultThumbnail';
import { getAnimatedThumbnails } from '../../../../../common/games/list/animated-thumbs';

const GameListItemFeatured = ({ game, url, dataId, saveCurrentUrl, as, provider }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const [errorImage, setErrorImage] = useState(false);

    return (
        <div className="game-list-item" data-id={dataId} style={{ boxShadow: `0 24px 24px -12px ${game.boxShadowColor}` }}>
            {getAnimatedThumbnails(game)[game.type] || (
                <div className="game-list-item--background">
                    <img
                        src={errorImage ? gameDefaultThumbnail() : game.imageSrc}
                        alt={game.name}
                        onError={() => setErrorImage(true)}
                    />
                </div>
            )}
            <div className="game-list-item--detail" style={{ backgroundColor: game.backgroundColor }}>
                <div className="game-info">
                    <div className="game-name">{game.name}</div>
                    <div className="provider-name">{provider.name}</div>
                </div>
                <Link href={url} as={as}>
                    <a
                        className="btn btn-submit btn-modern"
                        onClick={saveCurrentUrl}
                        data-testid={`game_featured_${game.id}`}
                    >
                        {tr['games.recommended_games.play']}
                    </a>
                </Link>
            </div>
        </div>
    );
};

export default GameListItemFeatured;
