import { useSelector } from 'react-redux';
import BetMinLimit from '../../svg-icons/game-thumbs/BetMinLimit';
import BetMaxLimit from '../../svg-icons/game-thumbs/BetMaxLimit';
import { useGameLimits } from '../../../hooks/useGameLimits';

const GameLimits = ({ table }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const { minLimit, maxLimit } = useGameLimits(table);

    return (
        <div className="game-list-item--limits-modern">
            <div className="game-list-item--text-details-minLimit">
                <div className="label">
                    <BetMinLimit />
                    <span>{tr['games.min_bet']}</span>
                </div>
                <div className="amount">{minLimit}</div>
            </div>
            <div className="game-list-item--text-details-maxLimit">
                <div className="label">
                    <BetMaxLimit />
                    <span>{tr['games.max_bet']}</span>
                </div>
                <div className="amount">{maxLimit}</div>
            </div>
        </div>
    );
};

export default GameLimits;
