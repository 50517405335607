import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import getCategoryId from '@tlf-e/brand-utils/dist/helpers/commonConfig/getCategoryId';
import GameListItemFeatured from './GameListItemFeatured';
import GameListItemDefault from './GameListItemDefault';
import GameListItemLive from './GameListItemLive';
import { getGamePath, getMenuUrl } from '../../../../../../utils/locationConfig';
import { setGameRouterHistory, setGameUrlAfterLogin } from '../../../../../../store/reducers/games';
import { GAME_CATEGORY_LIVE, GAME_ROUTER_PATHNAME, MENU_LOGIN } from '../../../../../../variables';

const GameListItem = ({ game, dataId, isLargeBg, isFeatured, isPromotionModal = false, isJackpot = false }) => {
    const gamesData = useSelector((state) => state.games.data);
    const isAuth = useSelector((state) => state.user.isAuth);
    const dispatch = useDispatch();
    const router = useRouter();
    const url = GAME_ROUTER_PATHNAME;
    const isLive = getCategoryId(gamesData.categories, GAME_CATEGORY_LIVE) === game?.categoryId;
    const gameAsPath = getGamePath(game, gamesData, router.locale);
    const provider = gamesData.softwares.find((item) => item.id === game?.softwareId);

    const saveCurrentUrl = (e) => {
        e.preventDefault();
        if (game.isDemoRestricted && !isAuth) {
            dispatch(setGameUrlAfterLogin(gameAsPath));
            const { href, as: asPath } = getMenuUrl(MENU_LOGIN, router);
            router.push(href, asPath);
        } else {
            dispatch(setGameRouterHistory(router));
            router.push(url, gameAsPath);
        }
    };

    const itemProps = {
        game: game,
        as: gameAsPath,
        url,
        saveCurrentUrl,
        dataId,
        provider,
        isLargeBg,
        isPromotionModal,
        isJackpot
    };

    if (isFeatured) {
        return <GameListItemFeatured {...itemProps} />;
    }

    return isLive ? <GameListItemLive {...itemProps} /> : <GameListItemDefault {...itemProps} />;
};

export default GameListItem;
