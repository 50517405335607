import { imageBaseUrl } from '../../services/http';
import { GAME_PROVIDERS_FOLDER_WHITE } from '../../variables';

const GameProviderLogo = ({ slug, folder = GAME_PROVIDERS_FOLDER_WHITE }) => {

    return (
        <div className="game-provider-logo img-wrapper">
            <img src={`${imageBaseUrl}/game-providers/${folder}/${slug}.png`} alt={slug}/>
        </div>
    );
};

export default GameProviderLogo;
