import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { getCategoryId } from '@tlf-e/brand-utils/dist/helpers/commonConfig';
import GameListItemDefault from './GameListItemDefault';
import GameListItemLive from './GameListItemLive';
import GameListItemFeatured from './GameListItemFeatured';
import {
    setGamePreviewGame,
    setGameRouterHistory,
    setGameUrlAfterLogin,
    toggleGamePreview
} from '../../../../../../store/reducers/games';
import { getGamePath, getMenuUrl } from '../../../../../../utils/locationConfig';
import { GAME_CATEGORY_LIVE, MENU_LOGIN } from '../../../../../../variables';

const GameListItem = ({ game, dataId, isFeatured, isJackpot = false }) => {
    const gamesData = useSelector((state) => state.games.data);
    const isAuth = useSelector((state) => state.user.isAuth);
    const router = useRouter();
    const dispatch = useDispatch();
    const isLive = getCategoryId(gamesData.categories, GAME_CATEGORY_LIVE) === game.categoryId;
    const getUrl = (gameEntity) => getGamePath(gameEntity, gamesData, router.locale);

    const clickHandler = (e, gameInfo) => {
        if (gameInfo.isDemoRestricted && !isAuth) {
            dispatch(setGameUrlAfterLogin(getUrl(gameInfo)));
        } else {
            if (isLive) {
                e.preventDefault();
                dispatch(toggleGamePreview(true));
                dispatch(setGamePreviewGame(game));
            }
            dispatch(setGameRouterHistory(router));
        }
    };

    const linkHandler = () => {
        if (game.isDemoRestricted && !isAuth) {
            const { href, as } = getMenuUrl(MENU_LOGIN, router);
            return {
                href,
                as
            };
        } else {
            return {
                href: getUrl(game)
            };
        }
    };

    const itemProps = {
        game,
        dataId,
        clickHandler,
        linkHandler,
        isJackpot
    };

    if (isFeatured) {
        return (
            <GameListItemFeatured
                {...itemProps}
                isLiveFeatured={isLive}
            />
        );
    }

    return isLive ? <GameListItemLive {...itemProps} /> : <GameListItemDefault {...itemProps} />;
};

export default GameListItem;
