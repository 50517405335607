const Play = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none">
        <ellipse cx="18.0017" cy="18.5001" rx="17.8571" ry="17.8797" fill="#8338EC" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.1221 17.0112C26.1059 17.7648 26.1059 19.2353 25.1221 19.9889C22.1488 22.2665 18.8288 24.0617 15.2862 25.3074L14.639 25.5349C13.4009 25.9703 12.0931 25.1409 11.9255 23.8756C11.4571 20.3403 11.4571 16.6598 11.9255 13.1245C12.0931 11.8592 13.4009 11.0298 14.639 11.4652L15.2862 11.6927C18.8288 12.9384 22.1488 14.7336 25.1221 17.0112ZM24.2116 18.824C24.4256 18.66 24.4256 18.3401 24.2116 18.1761C21.363 15.994 18.1823 14.2741 14.7883 13.0808L14.1411 12.8532C13.8188 12.7399 13.4484 12.9559 13.4007 13.316C12.9492 16.7242 12.9492 20.2759 13.4007 23.6841C13.4484 24.0442 13.8188 24.2602 14.1411 24.1469L14.7883 23.9193C18.1823 22.726 21.363 21.006 24.2116 18.824Z"
            fill="white"
        />
    </svg>
);

export default Play;
