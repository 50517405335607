import Link from 'next/link';
import LampJackpot from '../../../../../svg-icons/LampJackpot';

const GameListItemDefault = ({ game, dataId, linkHandler, clickHandler, isJackpot }) => {
    return (
        <Link {...linkHandler()}>
            <a className="game-list-item" data-id={dataId}>
                {isJackpot && (
                    <div className="game-list-item--jackpot-logo">
                        <LampJackpot />
                    </div>
                )}
                <div className="game-list-item-image" onClick={(e) => clickHandler(e, game)} data-testid={`game_${game.id}`}>
                    <img src={`${game.metadata.thumbnailBackground}`} alt={game.name} />
                </div>
            </a>
        </Link>
    );
};

export default GameListItemDefault;
