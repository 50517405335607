const BetMaxLimit = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M16 8C16 10.1218 15.1571 12.1565 13.6568 13.6568C12.1566 15.1571 10.1217 16 8 16C5.8783 16 3.84346 15.1571 2.34318 13.6568C0.842852 12.1566 6.96726e-07 10.1217 6.03983e-07 8C5.42603e-07 6.59578 0.369655 5.21619 1.07176 4.00003C1.77399 2.78387 2.78381 1.77405 4.00003 1.07176C5.21619 0.369655 6.59578 4.11071e-07 8 3.49691e-07C10.1218 2.56946e-07 12.1565 0.842852 13.6568 2.34318C15.1571 3.8434 16 5.8783 16 8ZM7.98406 4.00003C7.81018 3.99735 7.64266 4.06543 7.52 4.18887L4.89445 6.82245C4.73151 6.98506 4.66778 7.22222 4.72715 7.44465C4.78653 7.66696 4.96007 7.84084 5.18229 7.90067C5.40461 7.96038 5.64189 7.89699 5.80483 7.73438L7.34879 6.19199L7.34879 11.36C7.34879 11.5885 7.47078 11.7998 7.66876 11.9142C7.86686 12.0285 8.11084 12.0285 8.30881 11.9142C8.5068 11.7998 8.62878 11.5885 8.62878 11.36L8.62878 6.17742L10.1856 7.73421C10.3485 7.89715 10.5859 7.96077 10.8085 7.90117C11.031 7.84146 11.2049 7.66769 11.2645 7.44514C11.3241 7.2226 11.2605 6.98509 11.0975 6.82226L8.47196 4.18868C8.35131 4.06725 8.18714 3.99929 8.01593 3.99984L7.9839 3.99984L7.98406 4.00003Z"
            fill="#FF006E"
        />
    </svg>
);

export default BetMaxLimit;
