import { useSelector } from 'react-redux';
import Link from 'next/link';
import { gameDefaultThumbnail } from '../../../../../../utils/gameDefaultThumbnail';
import { LIVE_DEALER_STATUS_OPEN } from '../../../../../../variables';

const GameListItemLive = ({ game, dataId, linkHandler, clickHandler }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const blur = gameDefaultThumbnail();

    return (
        <Link {...linkHandler()}>
            <a
                className="game-list-item game-list-item-live"
                onClick={(e) => clickHandler(e, game)}
                data-id={dataId}
                data-testid={`game_${game.id}`}
            >
                {game.status !== LIVE_DEALER_STATUS_OPEN && (
                    <div
                        className="game-list-item--table-closed"
                        onClick={(e) => e.preventDefault()}
                        data-testid="table_closed_overlay"
                    >
                        <p>{tr['live_dealer.table_closed']}</p>
                    </div>
                )}
                <div className="game-list-item-image">
                    <div
                        className="game-list-item-image game-list-item-image--live-cover"
                        style={{
                            backgroundImage: game.thumbnail ? `url(${game.thumbnail})` : `url(${blur})`
                        }}
                    />
                    <img src={blur} alt={game.name} />
                </div>
                <div className="game-list-item-title">
                    <div className="game-name">{game.name}</div>
                </div>
            </a>
        </Link>
    );
};

export default GameListItemLive;
