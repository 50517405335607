import { useState } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { gameDefaultThumbnail } from '../../../../../../utils/gameDefaultThumbnail';
import { getAnimatedThumbnails } from '../../../../../common/games/list/animated-thumbs';

const GameListItemFeatured = ({ game, dataId, linkHandler, clickHandler, isLiveFeatured }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const { firstGameImageLink } = useSelector((state) => state.global.data);
    const { softwares } = useSelector((state) => state.games.data);
    const provider = softwares.find((item) => item.id === game?.softwareId);
    const [errorImage, setErrorImage] = useState(false);
    const gameImage = errorImage
        ? gameDefaultThumbnail()
        : isLiveFeatured
            ? (firstGameImageLink || game.thumbnail)
            : game.imageSrc;

    return (
        <div
            className="game-list-item"
            data-id={dataId}
            style={{ boxShadow: `0 24px 24px -12px ${game.boxShadowColor}` }}
            data-testid={`game_${game.id}`}
        >
            {getAnimatedThumbnails(game)[game.type] || (
                <div className="game-list-item--background">
                    <img src={gameImage} alt={game.name} onError={() => setErrorImage(true)} />
                </div>
            )}
            <div className="game-list-item--detail" style={{ backgroundColor: game.backgroundColor }}>
                <div className="game-info">
                    <div className="game-name">{game.name}</div>
                    <div className="provider-name">{provider.name}</div>
                </div>
                <Link {...linkHandler()}>
                    <a
                        className="btn btn-submit btn-modern"
                        onClick={(e) => clickHandler(e, game)}
                        data-testid={`game_featured_${game.id}`}
                    >
                        {tr['games.recommended_games.play']}
                    </a>
                </Link>
            </div>
        </div>
    );
};

export default GameListItemFeatured;
