const BetMinLimit = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M16 8C16 5.87824 15.1571 3.84346 13.6568 2.34318C12.1566 0.842851 10.1217 -2.56949e-07 8 -3.49691e-07C5.8783 -4.42433e-07 3.84346 0.84285 2.34318 2.34318C0.842852 3.8434 6.96726e-07 5.8783 6.03983e-07 8C5.42603e-07 9.40422 0.369655 10.7838 1.07176 12C1.77399 13.2161 2.78381 14.226 4.00003 14.9282C5.21619 15.6303 6.59578 16 8 16C10.1218 16 12.1565 15.1571 13.6568 13.6568C15.1571 12.1566 16 10.1217 16 8ZM7.98406 12C7.81018 12.0026 7.64266 11.9346 7.52 11.8111L4.89445 9.17755C4.73151 9.01494 4.66778 8.77778 4.72715 8.55535C4.78653 8.33304 4.96007 8.15916 5.18229 8.09933C5.40461 8.03962 5.64189 8.10301 5.80483 8.26562L7.34879 9.80801L7.34879 4.64005C7.34879 4.41148 7.47078 4.2002 7.66876 4.08582C7.86686 3.97154 8.11084 3.97154 8.30881 4.08582C8.5068 4.20022 8.62878 4.41148 8.62878 4.64005L8.62878 9.82258L10.1856 8.26579C10.3485 8.10285 10.5859 8.03923 10.8085 8.09883C11.031 8.15854 11.2049 8.33231 11.2645 8.55486C11.3241 8.7774 11.2605 9.01491 11.0975 9.17774L8.47196 11.8113C8.35131 11.9327 8.18714 12.0007 8.01593 12.0002L7.9839 12.0002L7.98406 12Z"
            fill="#3A86FF"
        />
    </svg>
);

export default BetMinLimit;
