import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../utils/formatCurrency';

export const useGameLimits = (game) => {
    const isAuth = useSelector((state) => state.user.isAuth);
    const currency = useSelector((state) => state.user.information.currency);
    const userCurrency = isAuth ? currency : 'EUR';
    const defaultCurrency = game.betLimits[userCurrency] || game.betLimits.EUR;
    const limitCurrency = game.betLimits[userCurrency] ? userCurrency : 'EUR';
    const limits = useMemo(() => {
        return {
            minLimit: formatCurrency(defaultCurrency?.min || 0, limitCurrency, 2),
            maxLimit: formatCurrency(defaultCurrency?.max || 0, limitCurrency, 2)
        };
    }, [defaultCurrency, limitCurrency]);

    return limits;
};
